import { createApp } from 'vue';
import { store } from '../inits/vue-store.js';
import toastHandler from '@/components/toast-handler/toast-handler.vue';
import { trans } from 'vue-components/src/mixin/trans.js';

const app = createApp({
    components: {
        toastHandler,
    },
    store,
});
app.use(store);
app.mixin(trans);
app.mount('#toast-container');
window.toastApp = app;
window.toastApp.$store = store;
window.toastApp.trans = trans.methods.trans;
